import React, { createContext, useState } from "react";
import Client from "shopify-buy";
import { useRef } from "react";

const client = Client.buildClient({
  domain: process.env.GATSBY_MYSHOPIFY_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_TOKEN,
});

const defaultValues = {
  isCartOpen: false,
  toggleCartOpen: () => {},
  toggleCartClose: () => {},
  cart: [],
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  formatMoney: value => {
    var num = "$" + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    return num;
  },
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [distanceFromTop, setIsDistanceFromTop] = useState(0);

  const notificationStyles = {
    position: "bottom-center",
    style: {
      color: "#000",
      fontFamily: "var(--font-sans)",
      fontSize: "14px",
      padding: "15px",
    },
    progressStyle: {
      background: "none",
      backgroundColor: "#000",
    },
  };

  const servicesRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        servicesRef,
        teamRef,
        contactRef,
        distanceFromTop,
        setIsDistanceFromTop,
        notificationStyles,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
